<template>
  <div
    class="md:mx-6 lg:mx-25 page_container__grid container 2xl:mx-auto items-center [ px-6 md:px-0 pb-10 pt-6 lg:pb-20 lg:pt-13 ]"
  >
    <section class="grid grid-cols-1 md:gap-x-5 h-full">
      <div class="flex flex-col">
        <div class="flex justify-end mb-3 md:hidden">
          <AppLink to="/" class="float-right [ flex items-center justify-center ] [ w-7 h-7 ]">
            <svg-icon-close class="text-primary-1-100" width="25" height="25" />
          </AppLink>
        </div>

        <section v-if="checkout && itemCount" class="flex flex-col justify-center items-start gap-2">
          <h1 class="text-lg-1 font-bold capitalize">
            {{ $t('checkoutAsGuest') }}
          </h1>
          <p class="text-primary-1-100 font-normal">{{ $t('checkoutAsGuestText') }}</p>
          <AppButton class="justify-center lg:w-92" as="AppLink" to="/checkout/guest-shipping">
            {{ $t('continueAsGuest') }}
          </AppButton>
        </section>

        <AuthSeperator v-if="checkout && itemCount" :text="$t('or')" class="my-6" />

        <h1 class="mb-5 text-lg md:text-xl font-bold capitalize">
          {{ $t('loginToYourAccount') }}
        </h1>
        <h2 class="mt-4 md:mx-0 md:w-auto lg:w-5/6 text-sm text-gray-900">
          {{ $t('loginToYourAccountDescription') }}
        </h2>
        <div class="flex flex-col">
          <form noValidate class="mt-8" @submit="onSubmit">
            <div class="space-y-8 flex flex-col gap-8">
              <TextInput
                id="username"
                name="email_phone"
                :label="$t('email_phone')"
                type="text"
                rules="required|email_phone"
                :aria-label="$t('email_phone')"
                full-border
                required
                :autofocus="true"
              />

              <TextInput
                id="password"
                name="password"
                :label="$t('password')"
                type="password"
                rules="required|min:8"
                filled
                full-border
                required
              />
            </div>

            <div class="mt-2 flex justify-end">
              <AppLink to="/auth/recover" class="text-sm flex items-center">
                <span class="ml-2">{{ $t('forgot') }}</span>
              </AppLink>
            </div>
            <AppButton class="mt-8 w-full lg:w-92 justify-center" :loading="isSubmitting" inverted>
              {{ $t('loginWithEmail') }}
            </AppButton>
          </form>
        </div>
      </div>
    </section>

    <hr class="flex lg:hidden w-full my-10 bg-gray-400" />

    <div class="flex flex-col w-full md:ml-auto">
      <div class="[ flex flex-col ] [ px-6 lg:px-8 pt-5 lg:pt-6 pb-7 ] bg-primary-1-05 rounded-4xs">
        <div class="relative w-full [ flex flex-col items-center justify-center md:justify-start ]">
          <div class="z-0 [ ] [ flex flex-col w-full ]">
            <h1 class="text-lg font-bold capitalize">
              {{ $t('newCustomer') }}
            </h1>

            <h2 class="mt-4 md:mx-0 md:w-auto lg:w-5/6 text-primary-1-60 text-sm">{{ $t('createNewAccount') }}</h2>
            <AppButton
              class="mt-6 w-full justify-center"
              as="AppLink"
              :to="itemCount && checkout ? `/checkout/shipping` : '/register'"
              variant="outline"
            >
              {{ itemCount && checkout ? $t('createAccountAndCheckout') : $t('createAccount') }}
            </AppButton>
          </div>
        </div>
      </div>

      <div class="flex w-full h-64 mt-2.5">
        <picture class="w-full">
          <source srcset="/login-bg.avif" type="image/avif" />
          <img src="/login-bg.jpeg" alt="login-bg" class="w-full h-full object-cover rounded-4xs" />
        </picture>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import * as yup from 'yup';
import { extendIsPhone } from '~/utils/text';

definePageMeta({
  layout: 'minimal',
});

const { login } = useLogin();
const { back, redirect } = useAppRouter();
const route = useRoute();
const checkout = !!route.query.checkout;
const { error, success } = useAlerts();
const { itemCount } = useCartAttributes();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loginWithEmail = ref(false);

const { t: $t } = useI18n({
  useScope: 'local',
});

const { t: _$t } = useI18n({ useScope: 'global' });

const { isSubmitting, handleSubmit, setErrors } = useForm({
  validationSchema: yup.object({
    // validate that should accept a value that is either a valid phone number or a valid email
    email_phone: yup
      .mixed()
      .required(_$t('validation.required') as string)
      .test('email_phone', _$t('validation.invalidEmailOrPhone') as string, function (value) {
        if (!value) return false;
        return isEmail(value.toString()) || extendIsPhone(value.toString());
      }),
    password: yup.string().required(_$t('validation.required') as string),
  }),
});

const onSubmit = handleSubmit(async ({ email_phone, password }) => {
  try {
    const user = await login({
      password,
      phone: email_phone,
    });
    if (process.client && /checkout\/guest-shipping/.test(localStorage.getItem('LS_ROUTE_KEY') || '')) {
      localStorage.setItem('LS_ROUTE_KEY', 'checkout/shipping');
    }

    back();
    success($t('success') as string, $t('greet', { name: user?.firstname }) as string);
  } catch (err) {
    if (/Invalid username or password./i.test((err as Error).message)) {
      error($t('error') as string, $t('invalidCredentials') as string);

      setErrors({
        email_phone: _$t('validation.invalidPhone') as string,
        password: _$t('validation.invalidPassword') as string,
      });
      return;
    }

    if (/account sign-in was incorrect/i.test((err as Error).message)) {
      error($t('error') as string, $t('invalidCredentials') as string);

      setErrors({
        email_phone: _$t('validation.invalidPhone') as string,
        password: _$t('validation.invalidEmail') as string,
      });
      return;
    }

    if (/\[GraphQL\] This account isn't verified\. Verify by OTP and try again\./.test((err as Error)?.message)) {
      return;
    }

    error('Sign in Error', 'An error occured while signing in, please try again later or contact support');
  }
});

useEvent('OTP_AUTHENTICATED', () => {
  redirect(checkout ? '/checkout/shipping' : '/');
});

useSeoMeta({
  title: $t('pageTitle') as string,
  description: $t('description') as string,
});
</script>

<style lang="postcss" scoped>
form {
  @apply w-full;
  /* max-width: 570px; */
}

.page_container__grid {
  @screen lg {
    @apply grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
  }
}
:deep(.TextInput input) {
  @apply h-10;
}
</style>

<i18n>
  {
    "en": {
      "pageTitle": "Log In",
      "description": "Log into your account for a seamless checkout process.",
      "email": "Email Address",
      "password": "Password",
      "cta": "Login",
      "forgot": "Forgot password?",
      "validation": {
        "invalidEmail": "You have entered an invalid email or password",
        "invalidPhone": "You have entered an invalid Phone or password"
      },
      "error": "Sign in Error",
      "genericError": "An error occurred while signing in, please try again later or contact support",
      "success": "You are signed in",
      "greet": "Welcome back {name}",
      "haveAnAccount": "Have An Account?",
      "loginToYourAccount": "Login With Your Email",
      "loginToYourAccountDescription": "Log into your account for a seamless checkout process, and to access your loyalty points",
      "login": "LOG IN",
      "or": "Or",
      "email_phone": "Enter Your Email/Phone",
      "invalidCredentials": "Invalid Phone Or Password",
      "newCustomer": "New Customer?",
      "createAccount": "Create an account",
      "createNewAccount": "Create a new account and enjoy a seamless checkout and ordering experience, and to be able to use loyal points",      "createAccountAndCheckout": "Create account and checkout",
      "loginWithEmail": "Login to my account",
      "continueAsGuest": "Continue As A Guest",
      "checkoutAsGuest": "Checkout As Guest",
      "checkoutAsGuestText": "No problem! Proceed to checkout as a guest."
    },
    "ar": {
      "pageTitle": "تسجيل الدخول",
      "description": "قم بالدخول لحسابك ليمكنك إتمام عملية الشراء بسهولة، تتبع طلباتك واكسب نقاط على كل عملية شراء",
      "email": "البريد الإلكتروني",
      "password": "كلمة السر",
      "cta": "تسجيل الدخول",
      "forgot": "هل نسيت كلمة المرور؟",
      "validation": { 
        "invalidEmail": "لقد ادخلت معلومات خاطئه عن حسابك , راجع بياناتك وحاول مجددا" 
        },
      "error": "فشل في تسجيل الدخول",
      "genericError": "حدث خطأ أثناء تسجيل الدخول ، يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بالدعم",
      "success": "تم تسجيل الدخول",
      "greet": "مرحبا بعودتك {name}",
      "haveAnAccount": "هل لديك حساب؟",
      "loginToYourAccount": "تسجيل الدخول باستخدام بريدك الإلكتروني",
      "loginToYourAccountDescription": "قم بتسجيل الدخول إلى حسابك للحصول على عملية دفع سلسة، وللوصول إلى نقاط الولاء الخاصة بك",
      "newCustomer": "عميل جديد؟",
      "createAccount": "أنشئ حسابًا",
      "createNewAccount": "أنشئ حسابًا جديدًا واستمتع بتجربة شراء سلسة، وحتى تتمكن من استخدام نقاط الولاء",      "createAccountAndCheckout": "إنشاء حساب والدفع",
      "loginWithEmail": "الدخول إلى حسابي",
      "continueAsGuest": "تابع كضيف",
      "checkoutAsGuest": "عملية الشراء كضيف",
      "checkoutAsGuestText": "لا مشكلة! تابع إلى عملية الشراء كضيف."
    }
  }
</i18n>
